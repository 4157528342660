<template>
  <div class="auth-wrapper">
    <div class="">
      <div class="col-lg-4 mx-auto form-holder">
        <div class="my-5 d-flex align-items-center justify-content-center">
          <img
            :src="
              AppTheme === 'dark_theme'
                ? require('@/assets/img/brand/ana_white.png')
                : require('@/assets/img/brand/ana.png')
            "
            class="logo"
            alt=""
          />
        </div>
        <form @submit.prevent="registerUser" class="app-form">
          <div class="mt-5">
            <h3 class="text-center ana_h3 ana_text-grey">
              Create a free account
            </h3>
            <p class="text-center ana_paragraph ana_text-grey">
              Join others like you
            </p>
          </div>
          <AuthWithGoogle />
          <div class="row">
            <div class="col-lg-12">
              <p class="text-danger small">{{ authMessage }}</p>
            </div>
            <div class="form-group col-lg-12">
              <label for="firstName" class="ana-label">First Name</label>
              <input
                type="text"
                name="firstName"
                class="ana-input w-100"
                v-model.trim="$v.firstName.$model"
                placeholder="First Name"
                required
              />
            </div>
            <div class="form-group col-lg-12">
              <label for="lastName" class="ana-label">Last Name</label>
              <input
                type="text"
                name="lastName"
                class="ana-input w-100"
                v-model.trim="$v.lastName.$model"
                placeholder="Last Name"
                required
              />
            </div>
            <div class="form-group col-lg-12">
              <label for="email" class="ana-label">Email</label>
              <input
                type="email"
                name="email"
                class="ana-input w-100"
                v-model.trim="$v.email.$model"
                placeholder="Email"
                required
              />
              <InlineAlert
                alertMessage="Be sure to provide a valid email"
                alertType="error"
                v-if="email && $v.email.$invalid"
              />
            </div>
            <div class="form-group col-lg-12">
              <label for="password" class="ana-label">Password</label>
              <div class="input-group mb-3 ana-input__group">
                <input
                  :type="type"
                  name="password"
                  class="form-control ana-input"
                  v-model.trim="$v.password.$model"
                  placeholder="Min. 6 Characters"
                  required
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text cursor-pointer"
                    id="basic-addon2"
                    @click="showPassword"
                  >
                    <i
                      class="fa fa-eye"
                      :class="{ 'fa fa-eye-slash': type == 'text' }"
                    ></i>
                  </span>
                </div>
              </div>
              <InlineAlert
                alertMessage="Password should be at least 6 characters"
                alertType="error"
                v-if="password && $v.password.$invalid"
              />
            </div>
            <div
              class="mt-3 mb-3 d-flex justify-content-center"
              v-if="isLoading"
            >
              <div class="linear-loader"></div>
            </div>
            <div class="form-group col-lg-12">
              <button
                type="submit"
                class="ana-btn ana-btn__fill w-100"
                :disabled="isLoading || $v.$invalid"
              >
                Register
              </button>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p class="ana-text--medium ana_text-grey">
              Already have an account?
              <router-link to="/login" class="ana_text-secondary">
                Login</router-link
              >
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength, between, email } from "vuelidate/lib/validators";
import axios from "axios";
import AuthWithGoogle from "@/components/AuthWithGoogle";
export default {
  name: "Register",
  metaInfo: {
    title: "Register - Ana app",
  },
  components: {
    AuthWithGoogle,
  },
  data() {
    return {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      type: "password",
      passIcon: "fa fa-eye",
      authMessage: "",
      isLoading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    firstName: { required },
    lastName: { required },
    password: {
      required,
      minLength: minLength(6),
    },
  },
  methods: {
    registerUser() {
      this.isLoading = true;
      const newUser = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
      };
      axios
        .post("register", newUser)
        .then((res) => {
          this.$store.dispatch("setToken", res.data.token);
          this.$store.dispatch("setUser", res.data.user);
          this.isLoading = false;
          this.$router.push("/dash/Overview");
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast(`${error.response.data.message}`, {
            timeout: 4000,
            type: "error",
          });
        });
    },
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.passIcon = "fa fa-eye-slash";
      } else {
        this.type = "password";
        this.passIcon = "fa fa-eye";
      }
    },
  },
};
</script>
